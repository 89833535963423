@import '../variables.less';

.custom-Input{
    input, .ant-select-selector {
        border-radius: 25px;
        background-color: @theme-blue !important;
        color: @white !important;
        padding: 5px 15px;
    }
    .ant-select-selector{
        padding: 0 15px;
    }
    .ant-input-password {
        border-radius: 25px;
        background-color: @theme-blue !important;
        color: @white !important;
        padding: 1px 8px;
    }
    .ant-input-password-icon {
        color: #fff
    }
}

.Submit-btn{
    button[type="submit"]{
        border-radius: 50%;
        background-color: @theme-darkblue;
        color: @white;
        padding: 10px 0;
        width: 50px;
        height: 50px;
        outline: none;
        float: right;
        clear: both;
        .anticon{
            font-size: 30px;
        }
    }
}

.common-form{
    padding: 0 12px;
    text-align: left;
    width: 100%;
    .ant-form-item-with-help {
        margin-bottom: 5px;
    }
    div[role='alert']{
        font-size: 11px;
        margin-left: 10px;
    }
    .custom-Input();
    .Submit-btn();
}

.auth-mixin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 10px;
}

.form-mixin{
    background-color: @white;
    padding: 50px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.title-mixin{
    font-size: 50px;
    font-weight: 400;
    margin: 0 0 30px 10px;
    color: @theme-darkblue;
}

.login-container{
    .auth-mixin();
    a[href="/register"]{
        color: @get-info;
        margin-top: 20px;
    }
    .form-container{
        .form-mixin();
        img{
            margin-bottom: 40px;
            width: 100%;
            height: 110px;
        }
        h1{
            .title-mixin();
        }
        .login-form{
            .common-form();
            min-width: 320px;
            a[href="/forgot-password"]{
                font-family: system-ui;
                margin-left: 10px;
                color: @theme-blue;
            }
        }
    }
}

.register-container{
    .auth-mixin();
    text-align: left;
    padding: 40px 20px;
    .form-container{
        .form-mixin();
        .ant-image{
            display: flex;
            justify-content: center;
            width: 100%;
            img{
                width: 250px;
                margin-bottom: 20px;
            }
        }
        .form-head{
            h1{
                .title-mixin();
                font-size: 35px;
                margin: 0 0 15px 0;
            }
            small{
                font-size: 13px;
            }
            margin-bottom: 50px;
        }
        #register-form{
            .common-form();
            min-width: 614px;
            div[class^="ant-col"]{
                width: 100%;
            }
            button[type="submit"]{
                background-color: @get-info;
            }
            padding: 0;
            #submit-container{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                a[href="/"]{
                    font-family: system-ui;
                    color: @theme-blue;
                }
            }
        }
    }
}

@media screen and (max-width: 556px){
    .login-container, .register-container{
        .form-container{
            padding: 30px 20px;
            .form-head{
                h1{
                    font-size: 35px;
                    margin-bottom: 10px;
                }
                small{
                    font-size: 10px;
                }
                margin-bottom: 20px;
            }
            #register-form{
                min-width: 260px;
            }
        }
    }
    .register-container{
        .form-container{
            .ant-image{
                justify-content: flex-start;
                margin-left: -10px;
            }
        }
    }
}
.ant-select-selector,
.ant-form-item-explain {
  text-align: left;
}

// Small Business Edit
.small__business__edit {
  .ant-select-selector,
  .ant-form-item-explain {
    text-align: left;
  }
  .edit__logo {
   position: relative;
    .ant-image-img {
      width: 172px;
      height: 132px;
    }
    .ant-btn-circle.ant-btn-sm {
      position: absolute;
      right: 4px;
      bottom: 10px;
    }
  }
}

.logo__wrapper {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  &.mt__20 {
    margin-top: 20px;
  }
  .ant-image-img {
    width: 172px;
    height: 132px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-col-20 {
    max-width: 100%;
  }
}

// Small Business Edit, view, add
.logo__wrapper {
 
  // width: 100%;
  .logo__form__fields {
    width: 100%;
    position: relative;
  }
  .logo__inner {
    position: absolute;
    right: 0;

    .ant-form-item {
      margin-bottom: 0;
      .ant-col-20 {
        max-width: none;
      }
      .ant-form-item-control-input-content {
        position: relative;
        .ant-upload.ant-upload-select-picture-card {
          margin-right: 0;
        }
      }
      .ant-btn-circle {
        position: absolute;
        right: 5px;
        bottom: 10px;
      }
    }
  }
}

// small business form list > form item styling
.form__list__btn {
  text-align: left;
  margin: 10px 0;
}

.form__item__save__btn {
  float: right;
  margin-right: 0px;
  margin-top: 0px;
  background-color: #82cf8f;
  color: white;
}

.form__item__remove__btn {
  float: right;
  margin-top: 0px;
  background-color: #d26a31;
  color: white;
}

.form__item {
  border: 1px solid gray;
  margin-bottom: 0px;
  padding-left: 7px;
  background-color: lightgray;
}

.form__list__wrapper {
  border: 1px solid gray;
  margin-bottom: 0px;
  padding-left: 7px;
  clear: both;
  background-color: lightgray;
}

.form__list__item {
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  margin-bottom: 0px;
  padding-left: 7px;
  background-color: lightgray;
}

// small business notes UI
.notes__logo > img {
  width: 80px;
  height: 80px;
}

.credential__manager {
  & > .cm__row {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    & > .cm__col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  }
}
